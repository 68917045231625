@import "~@blueprintjs/core/lib/css/blueprint.css";
@import "~@blueprintjs/table/lib/css/table.css";
@import "~react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

.App-logo {
  display: flex;
  justify-content: center;
  /* align horizontal */
  align-items: center;
  /* align vertical */
  height: 100%;
  width: 24px;
}

.App-logo-cabecera {
  display: flex;
  justify-content: center;
  /* align horizontal */
  align-items: center;
  /* align vertical */
  padding-left: 10px;
  height: 100%;
}

.App-menu {
  background-color: white;
  display: flex;
  font-size: calc(0px + 2vmin);
  color: white;
  position: fixed;
  left: -222px;
  top: 0;
  height: 100%;
  min-width: 10%;
  max-width: 75%;
  overflow: hidden;
  transition:0.3s;
  z-index: 50;
  box-shadow: 2px 2px 5px #4d3838b3;
  max-width: 50%;
  overflow: hidden;
  width: 246px;
}

.App-menu:hover { 
  left: 0px ;
  color: black;
  box-shadow: 2px 0px 2px #4d3838b3;
  overflow: auto;
  width: 20%;
  overflow-wrap: inherit
}

.App-link {
  color: white;
}

/**  LISTAS DE BDDS Y USUARIOS **/
/* div.ReactVirtualized__List{
  width:100% !important;
}
 */
.texto-blanco {
  color: white !important;
  padding-bottom: 16px
}

.bp3-icon {
  color: white
}

.bp3-tree .bp3-icon {
  color: black !important
}

.bp3-tree .bp3-icon-warning-sign{
  color: orange !important
}

.bp3-tree .bp3-icon-small-tick{
  color: green !important
}



.Cabecera {
  top: 0px;
  width: 100%;
  display: flex;
  flex-direction: row;
  color: white;
  justify-content: space-between;
  /* align horizontal */
  align-items: center;
  /* align vertical */
  background: #E31E24;
  text-transform: uppercase;
  height: 44px;
  box-shadow: 2px 2px 5px #4d3838b3;
}

.Cabecera .iconoCambiarCliente:hover,.Cabecera .iconoAyuda:hover,.Cabecera .iconoCerrarSesion:hover{
  cursor: pointer; 
}

.AreaTrabajo {
  padding-left: 3.4%;
  padding-right: 1%;
  flex: 1 1 auto;
}

.iconoAyuda {
  padding-right: 10px;
  color: white !important
}

.imagenInicio {
  position:relative;
  max-width:100%;
  max-height:100%;
  margin-top:-35%;
  margin-left:-40%;
}

.contenedorInicio {
  position:fixed;
  background-image: url(/static/media/logo_lex.36b632e1.png) !important;
  background-position: 55% 0% !important;
  width: 100%;
  height: 80%;
  position: fixed;
  background-repeat: no-repeat !important;
  background-size: contain;
  margin-top: 20px;
}

.iconoDerecha{
  margin: 5px;
  float: right;
}

.iconoIzquierda{
  margin: 5px;
}

.columnaEntera{
  min-width: 100%;
}

.tablaMitad{
  width: 50%;
  float: left;
  padding: 0px 5px 0px 5px;
}

.tablaMitadIzquierda{
  width: 50%;
  float: left !important;
}

.tablaMitadDerecha{
  width: 50%;
  float: right !important;
}

.tablaOchenta{
  width: 80%;
  float: left;
  padding: 0px 5px 0px 5px;
}

.tablaTreinta{
  width: 30%;
  float: left;
  padding: 0px 5px 0px 5px;
}

.tablaSetenta{
  width: 70%;
  float: left;
  padding: 0px 5px 0px 5px;
}

.tablaCuarenta{
  width: 40%;
  float: left;
  padding: 0px 5px 0px 5px;
}

.tablaSesenta{
  width: 60%;
  float: left;
  padding: 0px 5px 0px 5px;
}

.tablaTercio{
  width: 33%;
  float: left;
  padding: 0px 5px 0px 5px;
}

.tablaCuarto{
  width: 25%;
  float: left;
  padding: 0px 15px 0px 15px;
}

.logoMenu {
  max-width: 385px;
  width: 100% !important;
}

.grupoEmpresarial {
  width: 80%;
  height: 25px;
  float: left;
}

#sitio-web-corporativo {
  height: 40px !important;
  width: 80% !important;
  float: left;
}

#sitio-web-canal-denuncias {
  height: 40px !important;
  width: 80% !important;
  float: left;
}

.bp3-tree .bp3-icon, .bp3-tree .bp3-icon-standard, .bp3-tree .bp3-icon-large {
  color: white;
}

.contenedorPartible {
  display: flex;
}

.mitad-izquierda {
  float: left;
  width: 50%;
  flex: 1;
  padding: 1rem;
}

.mitad-derecha {
  float: right;
  width: 50%;
  flex: 1;
  padding: 1rem;
}

/* css login */
.login{
  background-color: #E31E24;
  position: fixed;
  height: 100%;
  min-width: 100%;
}

.formLogin{
  background-color: #E31E24;
  color:white;
  width:20% !important;
  height:45% !important;
  position:relative;
}

.formLogin p,.formLogin button{
  margin:0;
}
.formLogin button{
  margin-left:0 !important;
}
.formLogin .bp3-icon{
  color:black !important;
}
.formLogin h2{
  text-align: center;
}
.mostrar{
  display:block
}
.formLogin .bp3-dialog-footer{
  margin-top:10%;
}
.formLogin .bp3-dialog-header{
  padding:3%;
}
.form-imagen{
  width:100% !important;
  position: absolute;
  top:-45%;
  border-radius: 6px;
}
.ocultar{
  display:none;
}

.centrar{
  margin :0 auto !important;
  width:50%;
  height: 30%;
}

.centrar2{
  margin :0 auto !important;
  width:50%;
  text-align: center;
  height: 30%;
}

/** css lista **/

.list {
  padding: 10px;
}
.content{
  width: 100% !important;
}
.ReactVirtualized__List{
  border:1px solid #E8E8E8;
  width:100% !important;
  background-color: white;
}
.ReactVirtualized__Grid__innerScrollContainer{
  min-width:100% !important;
}
.row { 
  border-bottom: 1px solid #E8E8E8;
  text-align: right !important;
  margin: 0px 0;
  display: flex;
  align-items: center;
  padding:0 2%;
  height: 40px;
  width:100% !important;
}
.row p{
  width:100%;
}
.row:hover{
  background-color:#E8E8E8;
  cursor:pointer;
}

.image {
  margin-right: 10px;
}

.content {
  width:100%;
}

.linkRecordar p{
  width:100% !important;
  text-align: center;
  margin-bottom: 5% !important;
  color: #E8E8E8;
}

.ancho95 {
  width: 95%;
  max-width: 960px
}

.pixelAlrededor {
  padding: 3px
}

.pixelAlrededorGrande {
  padding: 10px;
  border-right: 5px;
  border-left: 5px;
  border-style: solid;
  border-color: transparent;
}

.pixelArriba {
  padding-top: 3px
}

/* react autosuggest */

.react-autosuggest__container {
  position: relative;
}

.react-autosuggest__input {
  width: 240px;
  height: 30px;
  padding: 10px 20px;
  border: 1px solid #aaa;
  border-radius: 4px;
}

.react-autosuggest__input--focused {
  outline: none;
}

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  width: 240px;
  border: 1px solid #aaa;
  background-color: #fff;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 2;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #ddd;
  margin-right: 30px;
}

.datos-empresa-dialog {
  width: 75%;
}

.editor-texto {
  font-size: 16px;
  max-height: 70%;
}

.editor-texto-modificado {
  height: 100% !important;
}

.tablaTreintaDerecha{
  width: 30%;
  float: right;
  padding: 0px 5px 0px 5px;
}

.calendario-wrapper {
  display: flex
}

.calendario-wrapper-tabla-izquierda {
  flex: 0 0 65%;
}

.calendario-wrapper-tabla-derecha {
  flex: 1;
}

.bp3-running-text ul {
  margin: 0 !important;
  padding-left: 5px;
}

.dialogo-denuncias {
  width: 55% !important
}

.bp3-callout {
  font-size: 16px;
  background-color: rgba (0,0,0,0.33);
  color: black;
}

.grisDeFondo {
  background-color: #dbdbdb;
}

.tarjetaAlturaCien {
  height: 100% !important;
}

.dialog-ancho {
  width: 65vw !important;
}

.dialog-medio-ancho {
  width: 45vw !important;
}

.dialog-extra-ancho {
  width: 75vw !important;
}

.dialog-scroll {
  max-height: 50vw !important;
  overflow: auto auto !important;
}


/* tablas html */

.tg  {border-collapse:collapse;border-spacing:0;margin:0px auto; width: 100%;}
.tg td{font-family:Arial, sans-serif;font-size:14px;padding:10px 5px;border-style:solid;border-width:1px;overflow:hidden;word-break:normal;border-color:black;}
.tg th{font-family:Arial, sans-serif;font-size:14px;font-weight:normal;padding:10px 5px;border-style:solid;border-width:1px;overflow:hidden;word-break:normal;border-color:black;}
.tg .tg-baqh{text-align:center;vertical-align:top}
.tg .tg-0lax{text-align:left;vertical-align:top}
.cell-vacia { border:solid 0px #fff !important; background-color: transparent !important }
@media screen and (max-width: 767px) {.tg {width: auto !important;}.tg col {width: auto !important;}.tg-wrap {overflow-x: auto;-webkit-overflow-scrolling: touch;margin: auto 0px;}}

.celdaFondoGris {
  background-color: #dbdbdb !important
}

.celdaFondoNegro {
  background-color: #000 !important
}

.celdaFondoVerde {
  background-color: forestgreen !important;
  color: white;
}
.celdaFondoAmarillo {
  background-color: yellow !important
}
.celdaFondoNaranja {
  background-color: orangered !important;
  color: white;
}
.celdaFondoRojo {
  background-color: #db3737 !important;
  color: white;
}
.celdaFondoBlanco {
  background-color: white !important
}

.celdaFondoRojoTextoCentradoBlanco {
  background-color: #db3737 !important;
  color: white;
}

.celdaFondoRojoTextoCentradoBlancoGestionFichajes {
  background-color: rgba(219, 55, 55, 0.15) !important;
  color: black;
}

.callout-centrado {
  text-align: center
}

.DayPicker-Day--esDiaConvocatoria {
 
  background-color:#db3737;
  color:white;
  
}

.Select {
	z-index: 0;
}

.Select.is-open {
	z-index: 1;
}

.CardCuadromandored{
  background-color: tomato;
}

.TarjetasCuadroMandoUnaPaCaLao{
  list-style: none;
  padding: 3px;
}

.TarjetasCuadroMandoUnaPaCaLao a{
  color: #182026;
  text-decoration: none;
}

.TarjetasCuadroMandoUnaPaCaLao:nth-child(odd){
  width: 50%;
  float: left;
}

.TarjetasCuadroMandoUnaPaCaLao:nth-child(even){
  width: 50%;
  float: right;
}

.CardCuadromandoorange{
  background-color: orange;
}

.CardCuadromandoyellow{
  background-color: yellow;
}

.CardCuadromandogreen{
  background-color: greenyellow;
}

.iconosCuadroMando {
  color: #867c7c96 !important;
  display: flex;
  justify-content: flex-end;
}

.cardLoading {
  height: 100%;
  width: 100%;
}

.acortarTablaRebelde {
  max-height: 45%
}

.acortarOtraTablaRebelde {
  max-height: 65%
}

/* Estos son los estilos para el organigrama*/
.initechNode {
  border: solid 3px #db3737;
  border-radius: 3px;
  padding: 5px;
  width: 150px;
  display: inline-block;
}

#initechOrgChart .orgNodeChildGroup .nodeGroupLineVerticalMiddle {
  border-right: solid 3px #db3737
}

#initechOrgChart .nodeLineBorderTop {
  border-top: solid 3px #db3737;
}

.contenedorOrganigrama{
  min-width:100% !important;
}

.tablasIdentificacion {
  width: 50%
}

.cardDocumentos {
  height: 280px;
  margin-bottom: 20px;
}

.cardCMI {
  display: flex;
  flex-wrap: inherit;
}

.cardCMI:nth-child(odd) {
  width: 25% !important;
  float: left !important;
  padding: 4px 2px;
  min-width: 25% !important;
}

.cardCMI:nth-child(even) {
  width: 25% !important;
  float: left !important;
  padding: 4px 2px;
  min-width: 25% !important;
}

.tablaToAncha {
  width: 100%;
  border-collapse: separate !important;
}

.textareaAlta {
  height: 150px !important;
}

.textareaEdicion {
  height: 21vw !important;
}

.textareaControlesyRecomendaciones {
  height: 31vw !important;
}

.separacionCentralTarjetas {
  padding-right: 15px;
}

.logoGlobalFinanz{
  width: 200px;
  height: 100%;
  padding-top: 25px;
}

.tabla-rebelde{
  max-height: 50% !important;
}

.imagenEnlineaIzquierda {
  display: inline-block;
  padding: 5px;
}

.textoRojo {
  color:#db3737
}

.imagenCentrada {
  max-width: 100%;
  max-height: 100%;
  padding: 5px;
}

.bp3-input:disabled {
  background: white !important;
  color: black !important;
}

.separarBotones {
  margin: 15px;
}

.separarBordes {
  margin: 5px;
}

.badge{
  background: #db3737;
  text-align: center;
  border-radius: 15px 15px 15px 15px;
  color: white;
  padding: 5px 9px;
}

.arreglarFileInputFeo{
  height: 34px;
}

.dialogGraficos {
  width: 40vw !important;
}

.dialog-alto {
  height: 40vw !important;
}

.textarea-alta {
  height: 30vw !important;
}

.subidaArchivosAlta {
  height: 180px
}

.iconoNaranja {
  color: orange !important;
}

.tdAbajo {
  z-index: 1 !important;
}

.selectArriba__menu {
  z-index: 9999;
}

.cardDocumentosHorizontal {
  margin-right: 3px;
  width: 49% !important;
}


.App-logo-pdf {
  display: flex;
  justify-content: center;
  /* align horizontal */
  align-items: center;
  /* align vertical */
  width: 35%;
}

.html-pdf {
  position: relative;
  margin: 2px;
  page-break-after: auto;
  height: 99%;
}

.body-pdf {
  margin: 0 0;
  /* bottom = footer height */
  page-break-after: auto;
  height: 99%;    
}

.libros-pdf {
  margin: 0 0;
  /* bottom = footer height */
  page-break-after: auto;
  height: 99%;
  width: 99% !important;
}

.footer-pdf {
  position: fixed;
  left: 15px;
  bottom: 25px;
  width: 100%;
  page-break-after: always;
  display: none;
}

.logo-solo-impresion {
  display: none;
}

.libro-socios-pdf .react-pdf__Page__canvas {
  display: block;
  user-select: none;
  width: 74%;
  /* height: 841px; */
  margin-left: 15%;
  margin-right: 15%;
}

@media print {

  .footer-pdf{
      display: block;
  }

  .logo-solo-impresion{
      display: block;
  }

  .callout-pdf {
    display: none;
  }

  .botonera-pdf {
    display: none;
  }

  .editor-texto {
    overflow: hidden;
  }

  .rdw-editor-toolbar {
    display: none;
  }

}

.tableAImprimir-pdf{
  max-height: 75% !important;
  page-break-after: auto;
}

.cardAImprimir-pdf{
  height: 85% !important;
  page-break-after: auto;
}

.tableAImprimir-catalogo-pdf {
  max-height: 85% !important;
  page-break-after: auto;
}

.tableAImprimir-identificacion-pdf {
  max-height: 55% !important;
  page-break-after: always;
  overflow: hidden;
}

.euro{
  font-size:20px;
  margin-top:50%;
  margin-right:10px;
}

.callout-rebelde {
  bottom: 0px;
  margin-bottom: 35px;
  margin-top: 10px;
}

/* tabla fichajes */

.tg2  {border-collapse:collapse;border-spacing:0;margin:0px auto;}
.tg2 td{font-family:Arial, sans-serif;font-size:14px;padding:10px 5px;border-style:solid;border-width:1px;overflow:hidden;word-break:normal;border-color:black;}
.tg2 th{font-family:Arial, sans-serif;font-size:14px;font-weight:normal;padding:10px 5px;border-style:solid;border-width:1px;overflow:hidden;word-break:normal;border-color:black;}
.tg2 .tg2-lbol{background-color:#9b9b9b;color:#000000;border-color:inherit;text-align:center}
.tg2 .tg2-1fcj{color:#000000;border-color:inherit;text-align:center}
.tg2 .tg2-xdwg{background-color:#000000;color:#000000;border-color:inherit;text-align:center}
@media screen and (max-width: 767px) {.tg2 {width: auto !important;}.tg2 col {width: auto !important;}.tg2-wrap {overflow-x: auto;-webkit-overflow-scrolling: touch;margin: auto 0px;}}


/* ajustes joyride */

#datos-votacion {
  padding-top: 20px !important;
  position:relative;
  clear: both;
  height: 170px;
}

#vista-calendario-actas{
  padding-top: 20px !important;
  position:relative;
  clear: both;
  height: 550px;
}

#contenedor-recursos-humanos{
  padding-top: 10px !important;
  position:relative;
  clear: both;
  height: 330px;
}

#contenedor-principal-edicion-riesgos{
  padding-top: 10px !important;
  position:relative;
  clear: both;
  height: 400px;
}

#boleano-registro-mercantil,
#boleano-comenzar-instruccion,
#booleano-desestimada,
#boleano-resolucion,
#boleano-comenzar-instruccion-siniestro,
#boleano-resolucion-siniestro {
  width: 100% !important;
}

#listado-competencias{
  position:relative;
  clear: both;
  height:100% !important;
}

#contenedor-denuncias{
  padding-top: 10px !important;
  position:relative;
  clear: both;
  height: 160px;
}

#datos-resolucion,#datos-resolucion-siniestro{
  padding-top: 10px !important;
  position:relative;
  clear: both;
  height: 250px;
}

#contenedor-cuadro-mandos{
  padding-top: 10px !important;
  position:relative;
  clear: both;
  min-height: 100% !important;
}

#vista-general-cuadro-identificacion {
  min-height: 180px;
}

.invisible {
  display: none;
}

.CMIflex {
  display: flex;
  flex-flow: wrap;
  align-items: stretch;
  align-content: stretch;
}

/* si una tabla no iimprime bien, a veces funciona poniendole dos div con la posicion manual */

.outer {
  overflow: hidden;
  position: relative;
}

.inner {
  position: absolute;
  right: -50px;
  top: 50px;
}

.texto-derecha {
  text-align: right;
  float: right;
}

.texto-izquierda {
  text-align: left;
  float: left;
}

.react-autosuggest__input {
  width: 150px !important;
}

.bp3-active {
  background-color: rgba(255, 255, 255, 0) !important;
}

.dialog-arriba {
  z-index: 150;
}

.dialog-base {
  z-index: 0;
}

.checkbox-recortado {
  width: 20%;
}

.cabecera-pdf-actas {
    flex: 1;
    justify-content: center;
    display: flex;
    padding-top: 10px;
}

.listhead {
  text-align: center;
  border: 1px solid lightgray;
  background-color: aliceblue;
  vertical-align: middle;
  height: 40px;
  padding-top: 10px;
}

.cabeceraTitleColumns > .bp3-table-column-name{
  background-color: #E31E24;
  color: #FFFFFF;
  text-align: center;
}

.header-class {
  background-color: rgba(219, 55, 55, 0.15);
  color: #182026;
  font-size: 14px;
}

.row-class {
  font-size: 12px;
}

.table-responsive {
  overflow-x: auto; /* Permite el desplazamiento horizontal */
  display: block;
  width: 100%;
}


.table-white {
  background-color: #FFFFFF;
}

.react-bootstrap-table-pagination-total {
  float: left;
  font-size: 14px;
}

.filter-label {
  padding-left: 15px;
}

.filter-label > .sr-only {
  display:none;
}

.react-bootstrap-table th > .react-bootstrap-table-sort-order > .caret {
  margin: 10px 6.5px;
}

.dropup .caret, .navbar-fixed-bottom .dropdown .caret {
  content: "";
  border-top: 0;
  border-bottom: 4px dashed;
  border-bottom: 4px solid\9;
}

.caret {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 2px;
    vertical-align: middle;
    border-top: 4px dashed;
    border-top: 4px solid\9;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
}

.tablaCien{
  width: 100%;
  float: left;
  padding: 5px 5px 0px 5px;
}

.displayNone {
  display: none;
}

.iconoAyudaSvg {
  padding-right: 10px;
  color: white !important;
  vertical-align: baseline;
  cursor: pointer;
}

.tableFicha {
  width: 100%;
  font-size: 12px;
}

.tableFichaCheck {
  width: 100%;
  font-size: 12px;
}

.tableFichaCheck td {
  border-width: 1px;
}

.bp3-calloutFicha {
  font-size: 12px !important;
  padding: 2px 5px 2px !important;
}
